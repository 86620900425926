<template>
	<v-dialog
		v-model="dialog"
		persistent
		:max-width="800"
		content-class="checkout-dialog"
	>
		<div @click.stop="eventStop">
			<v-card class="checkout-modal">
				<div class="checkout-modal__box" style="padding-right: 16px">
					<div class="modal-title">
						{{ selectTitle() }}
						<div>
							<v-btn
								v-if="invoiceSwitch && !selectInvoiceType && !selectDiscount"
								:disabled="entertain"
								class="select_btn default_btn selectDiscount mr-1"
								@click="clickInvoiceType()"
								small
							>
								發票類型
							</v-btn>
							<v-btn
								v-if="!selectInvoiceType && !selectDiscount"
								:disabled="entertain"
								class="select_btn default_btn selectDiscount"
								@click="clickDiscount()"
								small
							>
								折扣
							</v-btn>
						</div>
					</div>

					<div v-if="!selectInvoiceType && !selectDiscount" class="list_item">
						<div class="name">整筆招待</div>
						<div>
							<MyNewSwitch :value="entertain" @input="clickEntertain" simple />
						</div>
					</div>

					<div
						v-if="!selectInvoiceType && !selectDiscount && hasInvoiceTypeInput"
						class="list_item"
					>
						<div>{{ invoiceType.name }}</div>
						<div>{{ showInvoiceTypeInput }}</div>
					</div>

					<div v-if="!selectInvoiceType" class="modal-body">
						<div class="list_item">
							<div class="name">總計金額</div>
							<div>${{ $stepPrice(totalMoney) }}</div>
						</div>
						<!--	結帳畫面 自動折扣名稱	-->
						<div class="list_item" v-if="!selectDiscount && useOrderAutomaticDiscount">
							<div class="name">「{{ useOrderAutomaticDiscount.name }}」</div>
							<div>-${{ $stepPrice(automaticDiscountPrice) }}</div>
						</div>

						<!--order 、item 折扣畫面 顯示系統折扣-->
						<div class="list_item" v-if="automaticDiscountPrice && selectDiscount">
							<div class="name">系統折扣</div>
							<div>-${{ $stepPrice(automaticDiscountPrice) }}</div>
						</div>

						<div class="list_item">
							<div class="name">手動折扣</div>
							<div>-${{ $stepPrice(discountPrice) }}</div>
						</div>

						<div
							v-if="!selectDiscount"
							class="list_item"
							style="color: #d52828"
						>
							<div class="name">應收</div>
							<div>
								<span
									:class="{
										'text-decoration-line-through text--disabled mr-2':
											entertain,
									}"
								>
									${{ $stepPrice(totalMoney - automaticDiscountPrice - discountPrice) }}</span
								>
								<span v-if="entertain">$0</span>
							</div>
						</div>
						<div class="line"></div>
						<div v-if="!selectDiscount" class="list_item">
							<div class="name">實收金額</div>
							<div>
								<!--實收 = 應收-->
								${{
									selectCashPay
										? $stepPrice(pay)
										: $stepPrice(totalMoney - automaticDiscountPrice- discountPrice)
								}}
							</div>
						</div>
						<div class="list_item" style="color: #d52828" v-if="selectDiscount">
							<div class="name">折後</div>
							<div>${{ $stepPrice(totalMoney - automaticDiscountPrice - discountPrice) }}</div>
						</div>
					</div>

					<!--目標自動折扣-->
					<div class="list_item" v-if="!selectDiscount && nextOrderAutomaticDiscount">
						<div class="name">*差${{ nextOrderAutomaticDiscount.minimumSpendingAmount - totalMoney }}元 即可達到
							「{{ nextOrderAutomaticDiscount.name }}」
						</div>
					</div>

					<div v-if="selectInvoiceType">
						<div class="invoice-type-btn">
							<div
								v-for="(currentInvoiceType, index) in invoiceTypes"
								:key="index"
							>
								<v-btn
									:disabled="entertain"
									class="default_btn select_pay_type"
									@click="selectedInvoiceType(currentInvoiceType)"
									small
									:class="{
										'red red--text':
											invoiceType.name === currentInvoiceType.name,
										selectInvoiceType:
											invoiceType.name === currentInvoiceType.name,
									}"
								>
									{{ currentInvoiceType.name }}
								</v-btn>
							</div>
						</div>
						<div>
							<MyTextField
								v-if="invoiceType.code === 'uniformNumber'"
								ref="invoiceTypeInputRef"
								v-model="uniformNumberInput.number"
								label=""
								:error-messages="uniformNumberInput.errorMessage"
								:readonly="isInvoiceTypeInputReadonly"
								@onInput="uniformNumberInput.errorMessage === ''"
							>
							</MyTextField>
							<MyTextField
								v-else-if="invoiceType.code === 'phoneCarrier'"
								ref="invoiceTypeInputRef"
								v-model="phoneCarrierInput.number"
								label=""
								:error-messages="phoneCarrierInput.errorMessage"
								:readonly="isInvoiceTypeInputReadonly"
								@onInput="handleInvoicePhoneCarrierInput"
								:autofocus="true"
							>
							</MyTextField>
							<MyTextField
								v-else-if="invoiceType.code === 'donationNumber'"
								ref="invoiceTypeInputRef"
								v-model="donationNumberInput.number"
								label=""
								:error-messages="donationNumberInput.errorMessage"
								:readonly="isInvoiceTypeInputReadonly"
								@onInput="donationNumberInput.errorMessage === ''"
							>
							</MyTextField>
						</div>
					</div>

					<div
						v-if="!selectInvoiceType && !selectDiscount"
						class="lastCount"
						style="color: #d52828"
					>
						<div class="name">找零</div>
						<div>${{ selectCashPay ? $stepPrice(lastCount) : 0 }}</div>
					</div>
				</div>
				<div class="checkout-modal__box">
					<div class="right">
						<div v-if="!selectInvoiceType && !selectDiscount" class="pay_type">
							付款方式
						</div>
						<!-- 滑動容器 -->
						<v-window
							v-model="currentPayMethodPage"
							:show-arrows="paginatedPayMethods.length > 1"
							v-if="!selectInvoiceType && !selectDiscount"
							class="v-window-container"
						>
							<!-- 分頁內容 -->
							<v-window-item
								v-for="(page, pageIndex) in paginatedPayMethods"
								:key="pageIndex"
							>
								<div
									class="button-grid"
									:style="{
										gridTemplateColumns: `repeat(2, 1fr)`,
										gridTemplateRows: `repeat(${paginatedPayMethods[0].length / 2}, 1fr)`,
									}"
								>
									<!-- 按鈕內容 -->
									<v-btn
										v-for="(method, index) in page"
										:key="method.id || index"
										:class="{
											selected: payMethod === method.id,
										}"
										class="default_btn pay-button"
										@click="selectPayMethod(method)"
									>
										<span
											:ref="'payText' + index"
											:style="{
												fontSize: getTextFontSize(method),
											}"
										>
											{{ convertPayMethodName(method) }}
										</span>
									</v-btn>
								</div>
							</v-window-item>
						</v-window>

						<div
							class="pagination-indicator"
							v-if="!selectInvoiceType && !selectDiscount"
						>
							<span
								v-for="(_, index) in paginatedPayMethods.length"
								:key="index"
								:class="{
									one: paginatedPayMethods.length === 1,
									active:
										currentPayMethodPage === index &&
										paginatedPayMethods.length !== 1,
								}"
								class="dot"
								@click.stop="currentPayMethodPage = index"
							></span>
						</div>

						<div class="discount_type" v-if="selectInvoiceType">
							<div
								class="type_box"
								:class="{ active: invoiceTypeInputMode === 'manual' }"
								@click="handleInvoiceManualInput"
							>
								手動輸入
							</div>
							<div
								class="type_box"
								:class="{ active: invoiceTypeInputMode === 'scan' }"
								@click="handleInvoiceScanInput"
							>
								掃描輸入
							</div>
							<div
								class="type_box_select"
								:class="{ right: invoiceTypeInputMode === 'scan' }"
							/>
						</div>

						<div class="discount_type" v-if="selectDiscount">
							<div
								class="type_box"
								:class="{ active: discountType === 'money' }"
								@click="discountType = 'money'"
							>
								金額折扣
							</div>
							<div
								class="type_box"
								:class="{ active: discountType === 'person' }"
								@click="discountType = 'person'"
							>
								折數折扣
							</div>
							<div
								class="type_box_select"
								:class="{ right: discountType === 'person' }"
							/>
						</div>

						<div v-if="!isScannerForInvoice" class="keyboard">
							<div class="keyboard_box">
								<v-btn
									v-if="
										!selectDiscount ||
										(selectDiscount && discountType === 'money')
									"
									class="keyboard_item default_btn"
									v-for="e in usedCalc"
									:disabled="disableInvoiceKeyboard()"
									:key="`${e}`"
									@click.stop="onKeydownCalc(e)"
								>
									<template v-if="getCalcText(e) === 'BACKSPACE'">
										<svg-icon type="mdi" :path="path"></svg-icon>
									</template>
									<template v-else>
										{{ getCalcText(e) }}
									</template>
								</v-btn>
								<v-btn
									v-if="selectDiscount && discountType === 'person'"
									class="keyboard_item default_btn"
									:class="{ active: discountPerson === e[1] }"
									v-for="e in discountCalc"
									:key="`${e}`"
									@click.stop="onKeydownCalc(e[1])"
								>
									{{ e[0] }}
								</v-btn>
							</div>
						</div>

						<div v-if="isScannerForInvoice" class="web-scanner">
							<!--相機-->
							<qrcode-stream
								v-if="hasCamera"
								:track="paintOutline"
								:formats="formats"
								@init="onInit"
								@camera-on="onCameraReady"
								@decode="onDecode"
								@error="onError"
							/>

							<!-- 載入動畫 -->
							<div
								v-if="hasCamera && isLoadingToOpenCamera"
								class="loading-container"
							>
								<div class="loading-animation">
									<div class="spinner"></div>
								</div>
								<p class="loading-text">開啟相機中，請稍等...</p>
							</div>
							<div v-if="!hasCamera" class="loading-container">
								<p style="color: red" class="loading-text">
									沒有可用的相機設備
								</p>
							</div>
						</div>
					</div>
					<div class="actions">
						<v-btn
							class="actions_btn default_btn select_btn mr-2"
							@click="cancel"
							large
						>
							{{ selectDiscount && !discount ? '取消折扣' : '取消' }}
						</v-btn>
						<v-btn
							large
							@click="onSubmit"
							:color="submitButtonColor"
							:disabled="submitButtonDisable"
						>
							{{ selectSubmitButtonName() }}
						</v-btn>
					</div>
				</div>
			</v-card>
			<div class="actions mobile">
				<v-btn class="actions_btn default_btn select_btn" @click="cancel" large>
					{{ selectDiscount && !discount ? '取消折扣' : '取消' }}
				</v-btn>
				<v-btn
					large
					@click="onSubmit"
					:color="submitButtonColor"
					:disabled="submitButtonDisable"
				>
					{{ selectSubmitButtonName() }}
				</v-btn>
			</div>
		</div>
	</v-dialog>
</template>

<script>
import ForgetPassword from '@/views/ForgetPassword'
import MyTextField from '@/components/TextField.vue'
import { checkUniformNumber } from '@/rules/checkUniformNumber'
import { checkPhoneCarrier } from '@/rules/checkPhoneCarrier'
import { checkDonationNumber } from '@/rules/checkDonationNumber'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiBackspaceOutline } from '@mdi/js'
import { QrcodeStream } from 'vue-qrcode-reader/src'

const CALC_ZERO = 99
const CALC_SUBMIT_NUM = 100
const CALC_DOUBLE_ZERO = 98
const BACKSPACE = 97
const enumCalcText = {
	[CALC_ZERO]: 'C',
	[CALC_SUBMIT_NUM]: '確認',
	[CALC_DOUBLE_ZERO]: '00',
	[BACKSPACE]: 'BACKSPACE',
}

export default {
	name: 'MyCheckoutModal',
	components: {
		MyTextField,
		ForgetPassword,
		SvgIcon,
		QrcodeStream,
	},
	props: {
		value: {
			required: false,
			type: Boolean,
		},
		totalMoney: {
			required: true,
			type: Number,
		},
		discount: {
			required: false,
			type: Boolean,
			default: false,
		},
		uniformNumber: {
			required: false,
			type: String,
		},
		// 自動折扣訂單列表 (給訂單)
		orderAutomaticDiscountList: {
			required: false,
			type: Array,
		},
		// 訂單既有的訂單自動折扣
		// 手機點餐&前台點餐 點餐時不論有無付款皆會使用自動折扣，此作為手機、前台點餐結帳用
		existingOrderAutomaticDiscount: {
			required: false,
			type: Object,
		},
	},
	computed: {
		uniformNumberInput() {
			return this.invoiceTypes.find(
				invoiceType => invoiceType.code === 'uniformNumber',
			)
		},
		phoneCarrierInput() {
			return this.invoiceTypes.find(
				invoiceType => invoiceType.code === 'phoneCarrier',
			)
		},
		donationNumberInput() {
			return this.invoiceTypes.find(
				invoiceType => invoiceType.code === 'donationNumber',
			)
		},
		hasInvoiceTypeInput() {
			return this.invoiceTypes.some(invoiceType => !!invoiceType.confirmNumber)
		},
		showInvoiceTypeInput() {
			const currentInvoiceType = this.getCurrentInvoiceType()
			return currentInvoiceType.confirmNumber
		},
		usedCalc() {
			return this.selectInvoiceType ? this.invoiceCalc : this.calc
		},
		isScannerForInvoice() {
			return this.selectInvoiceType && this.invoiceTypeInputMode === 'scan'
		},
		invoiceSwitch() {
			const invoiceSwitch = this.$store.state.storage.invoiceSwitch
			return invoiceSwitch.admin && invoiceSwitch.store
		},
		isReactNative() {
			return this.$isReactNative()
		},
		// 找零金額
		lastCount() {
			const count = this.pay - this.totalMoney + this.discountPrice + this.automaticDiscountPrice
			return this.entertain ? this.pay : count > 0 ? count : 0
		},
		// 判斷變色
		submitButtonColor() {
			if (this.selectDiscount) {
				// 折扣情況
				if (
					!this.entertain &&
					(this.discountPerson > 0 || this.discountPrice > 0)
				) {
					return 'rgb(237, 180, 119)'
				} else {
					return 'spaicel2 bold'
				}
			}
			// 結帳情況
			if (this.selectCashPay) {
				if (this.pay >= this.totalMoney - this.discountPrice) {
					return 'rgb(237, 180, 119)'
				} else {
					return 'spaicel2 bold'
				}
			} else {
				return 'rgb(237, 180, 119)'
			}
		},
		payMethods() {
			return this.$store.state.storage.payMethods
		},
		// 判斷disable
		submitButtonDisable() {
			if (this.selectDiscount) {
				// 折扣判斷條件
				const count = this.totalMoney - this.automaticDiscountPrice - this.discountPrice
				return !this.entertain && count < 0
			}
			// 結帳判斷條件
			// 有店家不使用 計算機功能
			return false
		},
		paginatedPayMethods() {
			const pageSize = 6
			const pages = []
			for (let i = 0; i < this.payMethods.length; i += pageSize) {
				pages.push(this.payMethods.slice(i, i + pageSize))
			}
			return pages
		},
	},
	created() {},
	data() {
		return {
			dialog: false,
			entertain: false,
			// 手動折扣金額
			discountPrice: 0,
			// 手動折扣百分比 (typo)
			discountPerson: null,
			// 自動折扣金額(折數換算金額、金額)
			automaticDiscountPrice: null,
			saveDiscountPrice: 0,
			pay: 0,
			discountType: 'money',
			selectDiscount: false,

			selectInvoiceType: false,
			invoiceType: { code: 'uniformNumber', name: '統編' },
			invoiceTypes: [
				{
					code: 'uniformNumber',
					name: '統編',
					number: '',
					confirmNumber: '',
					errorMessage: '',
				},
				{
					code: 'phoneCarrier',
					name: '手機載具',
					number: '',
					confirmNumber: '',
					errorMessage: '',
				},
				{
					code: 'donationNumber',
					name: '捐贈碼',
					number: '',
					confirmNumber: '',
					errorMessage: '',
				},
			],
			invoiceTypeInputMode: 'manual',
			id: null,
			scannedBarCodeByWeb: '',
			scannedBarCodeError: '',

			payMethod: 'CASH', // 當前選中的付款方式
			selectCashPay: true, // 是否為現金
			currentPayMethodPage: 0, // 當前頁碼

			calc: [7, 8, 9, 4, 5, 6, 1, 2, 3, 0, CALC_DOUBLE_ZERO, CALC_ZERO],
			invoiceCalc: [7, 8, 9, 4, 5, 6, 1, 2, 3, 0, CALC_ZERO, BACKSPACE],
			discountCalc: [
				['95折', 0.95],
				['9折', 0.9],
				['85折', 0.85],
				['8折', 0.8],
				['75折', 0.75],
				['7折', 0.7],
				['65折', 0.65],
				['6折', 0.6],
				['55折', 0.55],
				['5折', 0.5],
				['45折', 0.45],
				['4折', 0.4],
			],

			isLoadingToOpenCamera: true, // 控制是否顯示載入動畫
			hasCamera: false,
			isInvoiceTypeInputReadonly: false,
			path: mdiBackspaceOutline,

			formats: [
				'qr_code', // QR碼 (常用於台灣的支付及發票)
				'ean_13', // EAN-13 (超商商品條碼)
				'ean_8', // EAN-8 (小型商品條碼)
				'code_128', // Code 128 (物流與庫存常用)
				'upc_a', // UPC-A (國際商品條碼)
				'code_39', // Code 39 (某些系統與自動化設備使用)
				'pdf417', // PDF417 (如中華郵政常見條碼)
				'itf', // ITF (箱號條碼，物流業部分使用)
			],

			// 套用的訂單自動折扣
			useOrderAutomaticDiscount: null,
			// 目標訂單自動折扣
			nextOrderAutomaticDiscount: null,
		}
	},
	watch: {
		dialog() {
			if (!this.dialog) {
				this.init()
			} else {
				this.selectDiscount = this.discount
				const existingOrderAutomaticDiscount = this.existingOrderAutomaticDiscount
				// 結帳畫面(非卡片折扣) 套用訂單自動折扣
				if (!this.discount && this.orderAutomaticDiscountList.length > 0) {
					if (!existingOrderAutomaticDiscount?.id) {
						// 如果訂單沒有套用自動折扣
						this.applyOrderAutomaticDiscount()
					} else {
						// 如果訂單已經套用自動折扣
						this.applyExistingOrderAutomaticDiscount(existingOrderAutomaticDiscount)
					}
					this.findNextOrderAutomaticDiscount()
				}

				if (this.payMethods.length > 0) {
					this.selectPayMethod(this.payMethods[0])
				} else {
					this.selectCashPay = false
					this.payMethod = null
				}

				if (this.uniformNumber) {
					this.invoiceType = { code: 'uniformNumber', name: '統編' }
					const currentInvoiceType = this.getCurrentInvoiceType()
					currentInvoiceType.number = this.uniformNumber
					currentInvoiceType.confirmNumber = this.uniformNumber
				}
			}
		},
	},
	async mounted() {
		if (!this.hasCamera) {
			try {
				const devices = await navigator.mediaDevices.enumerateDevices()
				const videoDevices = devices.filter(
					device => device.kind === 'videoinput',
				)

				if (videoDevices.length > 0) {
					this.hasCamera = true
				}
			} catch (err) {
				console.error('初始化相機失敗:', err)
			}
		}
	},
	methods: {
		paintOutline(detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

				ctx.strokeStyle = 'red'
				ctx.beginPath()
				ctx.moveTo(firstPoint.x, firstPoint.y)
				for (const { x, y } of otherPoints) {
					ctx.lineTo(x, y)
				}
				ctx.lineTo(firstPoint.x, firstPoint.y)
				ctx.closePath()
				ctx.stroke()
			}
		},
		onInit() {
			this.isLoadingToOpenCamera = false
		},
		async onCameraReady() {
			this.scannedBarCodeError = ''
			try {
				const devices = await navigator.mediaDevices.enumerateDevices()
				const videoDevices = devices.filter(({ kind }) => kind === 'videoinput')

				if (videoDevices.length > 0) {
					const defaultDevice = videoDevices[0] // 選取第一個相機作為默認
					console.log(
						`默認選擇的相機: ${defaultDevice.label} (ID: ${defaultDevice.deviceId})`,
					)
				} else {
					this.scannedBarCodeError = '沒有檢測到相機設備'
				}
			} catch (error) {
				this.scannedBarCodeError = '獲取相機設備時發生錯誤'
				console.error('獲取相機設備時發生錯誤:', error)
			} finally {
				this.isLoadingToOpenCamera = false
			}
		},
		onDecode(result) {
			this.scannedBarCodeByWeb = result

			const currentInvoiceType = this.getCurrentInvoiceType()
			if (currentInvoiceType) {
				currentInvoiceType.number = result
			}

			if (this.id) clearTimeout(this.id)
			this.id = setTimeout(() => {
				if (this.scannedBarCodeByWeb === result) {
					this.scannedBarCodeByWeb = ''
				}
			}, 5000)

			this.onSubmit()
		},
		getCurrentInvoiceType() {
			return this.invoiceTypes.find(
				invoiceType => invoiceType.code === this.invoiceType.code,
			)
		},
		onError(err) {
			this.scannedBarCodeError = `[${err.name}]: `

			if (err.name === 'NotAllowedError') {
				this.scannedBarCodeError += '您需要授予相機訪問權限'
			} else if (err.name === 'NotFoundError') {
				this.scannedBarCodeError += '此設備沒有相機'
			} else if (err.name === 'NotSupportedError') {
				this.scannedBarCodeError += '需要安全上下文（HTTPS 或 localhost）'
			} else if (err.name === 'NotReadableError') {
				this.scannedBarCodeError += '相機是否正在被其他應用程式使用？'
			} else if (err.name === 'OverconstrainedError') {
				this.scannedBarCodeError += '已安裝的相機不符合要求'
			} else if (err.name === 'StreamApiNotSupportedError') {
				this.scannedBarCodeError += '此瀏覽器不支援 Stream API'
			} else if (err.name === 'InsecureContextError') {
				this.scannedBarCodeError +=
					'相機訪問僅允許在安全上下文中。請使用 HTTPS 或 localhost，而非 HTTP。'
			} else {
				this.scannedBarCodeError += err.message
			}
			console.log(this.scannedBarCodeError)
		},
		cancel() {
			if (this.selectInvoiceType) {
				this.recoverInvoiceType()
				this.selectInvoiceType = false
				this.invoiceTypeInputMode = 'manual'
				this.isLoadingToOpenCamera = true
			} else if (this.selectDiscount && !this.discount) {
				this.selectDiscount = false
				this.discountPrice = this.saveDiscountPrice
			} else {
				this.dialog = false
				this.$emit('onCancel')
			}
		},
		recoverInvoiceType() {
			const currentConfirmInvoiceType = this.invoiceTypes.find(
				invoiceType => !!invoiceType.confirmNumber,
			)
			if (currentConfirmInvoiceType) {
				this.invoiceType = {
					code: currentConfirmInvoiceType.code,
					name: currentConfirmInvoiceType.name,
				}
				currentConfirmInvoiceType.number =
					currentConfirmInvoiceType.confirmNumber
			}
		},
		init() {
			this.entertain = false
			this.discountPrice = 0
			this.pay = 0
			this.selectDiscount = false
			this.saveDiscountPrice = 0
			this.discountPerson = null
			this.discountType = 'money'
			this.initInvoice()
			this.useOrderAutomaticDiscount = null
			this.nextOrderAutomaticDiscount = null
			this.automaticDiscountPrice = null

			// 等待 DOM 更新後重置頁面
			this.$nextTick(() => {
				this.currentPayMethodPage = 0
			})

		},
		async onSubmit(ev) {
			const currentInvoiceType = this.getCurrentInvoiceType()
			if (this.selectInvoiceType) {
				if (!(await this.checkInvoiceTypeInput(currentInvoiceType))) {
					return
				}
				this.selectInvoiceType = false
				currentInvoiceType.errorMessage = ''
				currentInvoiceType.confirmNumber = currentInvoiceType.number
				this.initOtherInvoiceTypeInput(currentInvoiceType)
			} else if (this.selectDiscount && !this.discount) {
				this.saveDiscountPrice = this.discountPrice
				this.selectDiscount = false
			} else {
				if (!this.payMethods.some(method => method.id === this.payMethod)) {
					this.$store.commit('showMessage', {
						text: '付款方式已被關閉請重新選擇',
						color: 'error',
					})
					return
				}
				this.dialog = false
				this.$emit('onSubmit', {
					paid: true,
					discountPrice: this.discountPrice,
					// automaticDiscountPrice: this.entertain ? 0 : this.automaticDiscountPrice, // 這個參數給前端畫面更新用，不發送api ， 可不使用
					entertain: this.entertain,
					payMethod: this.payMethod,
					discountPerson: this.discountPerson,
					change: this.selectCashPay ? this.lastCount : null,
					cash: this.selectCashPay ? this.pay : null,
					invoiceInfo: {
						type: this.invoiceType.code,
						number: currentInvoiceType.confirmNumber,
					},
				})
			}
		},
		initInvoice() {
			this.invoiceType = { code: 'uniformNumber', name: '統編' }
			this.invoiceTypeInputMode = 'manual'
			this.isLoadingToOpenCamera = true
			this.invoiceTypes.forEach(invoiceType => {
				invoiceType.number = ''
				invoiceType.confirmNumber = ''
				invoiceType.errorMessage = ''
			})
		},
		initOtherInvoiceTypeInput(currentInvoiceType) {
			this.invoiceTypes.forEach(invoiceType => {
				if (invoiceType.code !== currentInvoiceType.code) {
					invoiceType.number = ''
					invoiceType.confirmNumber = ''
					invoiceType.errorMessage = ''
				}
			})
		},
		async checkInvoiceTypeInput(invoiceType) {
			if (invoiceType.number !== 0 && !invoiceType.number) {
				return true
			}

			if (
				invoiceType.code === 'uniformNumber' &&
				!checkUniformNumber(invoiceType.number)
			) {
				invoiceType.errorMessage = '統編不正確'
				return false
			}

			if (invoiceType.code === 'phoneCarrier') {
				const validPhoneCarrier = await checkPhoneCarrier(
					invoiceType.number,
					this.$store.state.storage.storeId,
				)
				if (!validPhoneCarrier) {
					invoiceType.errorMessage = '手機載具不正確'
					return false
				}
			}

			if (
				invoiceType.code === 'donationNumber' &&
				!checkDonationNumber(invoiceType.number)
			) {
				invoiceType.errorMessage = '捐贈碼不正確'
				return false
			}

			return true
		},
		getCalcText(num) {
			return enumCalcText[num] ?? num
		},
		eventStop(e) {
			e.stopPropagation()
		},
		selectTitle() {
			let title = ''
			if (this.selectInvoiceType) {
				title = '發票類型'
			} else if (this.selectDiscount) {
				title = '折扣'
			} else {
				title = '結帳'
			}
			return title
		},
		setCurrentInvoiceType() {
			const currentInvoiceType = this.invoiceTypes.find(
				invoiceType => !!invoiceType.confirmNumber,
			)
			if (currentInvoiceType) {
				const { code, name } = currentInvoiceType
				this.invoiceType = { code, name }
				return true
			}
			return false
		},
		initReactNativeInvoiceType() {
			if (this.isReactNative) {
				this.invoiceType = {
					code: 'phoneCarrier',
					name: '手機載具',
				}
				this.invoiceTypeInputMode = 'scan'
			}
		},
		clickInvoiceType() {
			this.selectInvoiceType = !this.selectInvoiceType
			if (this.selectInvoiceType) {
				this.entertain = false
			}

			if (!this.setCurrentInvoiceType()) {
				this.initReactNativeInvoiceType()
			}
		},
		clickDiscount() {
			this.selectDiscount = !this.selectDiscount
			if (this.selectDiscount) {
				this.entertain = false
			}
			// 手動開啟訂單折扣視窗 套用自動折扣金額
			this.setAutomaticDiscountPrice()
		},
		clickEntertain() {
			this.entertain = !this.entertain
			if (this.entertain) {
				this.selectDiscount = false
				this.discountPrice = 0
				this.pay = 0
			}
		},
		async selectedInvoiceType(currentInvoiceType) {
			this.invoiceType = currentInvoiceType

			if (currentInvoiceType.code === 'phoneCarrier') {
				if (this.isReactNative) {
					this.invoiceTypeInputMode = 'scan'
					this.isInvoiceTypeInputReadonly = false
				} else if (this.invoiceTypeInputMode === 'manual') {
					this.isInvoiceTypeInputReadonly = false
					await this.$nextTick()
					this.$refs.invoiceTypeInputRef.focus()
				}
			} else {
				this.invoiceTypeInputMode = 'manual'
				this.isLoadingToOpenCamera = true
				this.isInvoiceTypeInputReadonly = true
			}
		},
		setDiscountPrice(num) {
			if (this.discountPerson === num) {
				this.discountPerson = null
				this.discountPrice = 0
			} else {
				this.discountPerson = num
				this.discountPrice = this.totalMoney - this.automaticDiscountPrice - Math.ceil((this.totalMoney - this.automaticDiscountPrice) * num)
			}
		},
		selectPayMethod(method) {
			this.payMethod = method.id
			this.selectCashPay = method.statisticKey === 'CASH'
		},
		convertPayMethodName(method) {
			if (method.statisticKey === 'LINE_PAY_OFFLINE') {
				return 'LinePay'
			}
			const text = method.name
			return text.length > 8 ? text.slice(0, 8) : text
		},
		getTextFontSize(method) {
			if (
				method.statisticKey === 'LINE_PAY_OFFLINE' ||
				method.name.length < 6
			) {
				return '1.125rem'
			}
			return 1.125 - this.$store.state.storage.scaleFont * 0.12 + 'rem'
		},
		async handleInvoiceManualInput() {
			this.invoiceTypeInputMode = 'manual'
			this.isLoadingToOpenCamera = true
			if (this.invoiceType.code === 'phoneCarrier') {
				this.isInvoiceTypeInputReadonly = false
				await this.$nextTick()
				this.$refs.invoiceTypeInputRef?.focus()
			}
		},
		handleInvoiceScanInput() {
			this.invoiceTypeInputMode = 'scan'
			if (this.invoiceType.code === 'phoneCarrier') {
				this.isInvoiceTypeInputReadonly = true
			}
		},
		disableInvoiceKeyboard() {
			let disable = false
			if (!this.selectDiscount && !this.selectCashPay) {
				disable = true
			} else if (this.entertain) {
				disable = true
			} else if (this.selectInvoiceType) {
				if (
					this.invoiceType.code === 'phoneCarrier' &&
					this.invoiceTypeInputMode === 'manual'
				) {
					disable = true
				} else if (this.invoiceTypeInputMode === 'scan') {
					disable = true
				}
			}
			return disable
		},
		onKeydownCalc(num) {
			const currentInvoiceType = this.getCurrentInvoiceType()

			if (this.selectDiscount && this.discountType === 'person') {
				this.setDiscountPrice(num)
				return
			} else if (
				this.selectDiscount &&
				this.discountType === 'money' &&
				this.discountPerson !== null
			) {
				this.discountPerson = null
				this.discountPrice = 0
			} else if (
				this.selectInvoiceType &&
				this.invoiceTypeInputMode === 'manual'
			) {
				currentInvoiceType.errorMessage = ''
			}

			switch (num) {
				case CALC_DOUBLE_ZERO:
					this.changeCount('00', true, true)
					break
				case CALC_ZERO:
					if (this.selectDiscount) {
						this.discountPrice = 0
					} else if (this.selectInvoiceType) {
						currentInvoiceType.number = ''
					} else {
						this.pay = 0
					}
					break
				case BACKSPACE:
					currentInvoiceType.number = currentInvoiceType.number.slice(0, -1)
					break

				default:
					if (this.clickKeyBoard) {
						this.changeCount(num, false, true)
					} else {
						this.changeCount(num)
						this.clickKeyBoard = true
					}
					break
			}
		},
		changeCount(num, add, concat, space) {
			let count
			const currentInvoiceType = this.getCurrentInvoiceType()

			if (this.selectInvoiceType) {
				count = currentInvoiceType.number
			} else {
				count = this.selectDiscount ? this.discountPrice : this.pay
			}

			if (space && count < 10) {
				return
			}

			if (this.selectDiscount) {
				this.discountPrice = concat
					? Number(`${count}${num}`)
					: space
					? Math.floor(count / 10)
					: num
			} else if (this.selectInvoiceType) {
				currentInvoiceType.number = `${count}${num}`
			} else {
				this.pay = concat
					? Number(`${count}${num}`)
					: space
					? Math.floor(count / 10)
					: num
			}
		},
		selectSubmitButtonName() {
			let buttonName = ''
			if (this.selectInvoiceType) {
				buttonName = '確認'
			} else if (this.selectDiscount) {
				buttonName = '確認折扣'
			} else {
				buttonName = '結帳'
			}
			return buttonName
		},
		// 自動套用已達標中最高滿額金額的訂單自動折扣
		applyOrderAutomaticDiscount() {
			let select
			this.orderAutomaticDiscountList.forEach(automaticDiscount => {
				if (this.totalMoney >= automaticDiscount.minimumSpendingAmount) {
					if (!select || automaticDiscount.minimumSpendingAmount > select.minimumSpendingAmount) {
						select = automaticDiscount
					}
				}
			})

			if (select) {
				this.useOrderAutomaticDiscount = select
				this.setAutomaticDiscountPrice() // 設置自動折扣金額
			} else {
				this.useOrderAutomaticDiscount = null
				this.automaticDiscountPrice = null
			}
		},
		// 尋找下一個尚未達標最低滿額金額的自動折扣
		findNextOrderAutomaticDiscount() {
			let select
			this.orderAutomaticDiscountList.forEach(automaticDiscount => {
				if (this.totalMoney < automaticDiscount.minimumSpendingAmount) {
					if (!select || automaticDiscount.minimumSpendingAmount < select.minimumSpendingAmount) {
						select = automaticDiscount
					}
				}
			})

			this.nextOrderAutomaticDiscount = select || null
		},
		// 設置自動折扣減免金額
		setAutomaticDiscountPrice() {
			if (!this.useOrderAutomaticDiscount) {
				this.automaticDiscountPrice = null
				return
			}

			if (this.useOrderAutomaticDiscount.discountType === 'AMOUNT') {
				this.automaticDiscountPrice = this.useOrderAutomaticDiscount.discountAmount
			}

			if (this.useOrderAutomaticDiscount.discountType === 'PERCENTAGE') {
				// 避免浮點誤差 折數轉小數時先取整
				this.automaticDiscountPrice = this.totalMoney - Math.ceil(this.totalMoney * (this.useOrderAutomaticDiscount.discountPercentage * 0.01).toFixed(2))
			}
		},
		// 從自動折扣id 尋找並套用已存在的自動折扣
		applyExistingOrderAutomaticDiscount(existingAutomaticDiscount) {
			this.useOrderAutomaticDiscount = existingAutomaticDiscount
			this.automaticDiscountPrice = existingAutomaticDiscount.automaticDiscountPrice
		},
		// 掃描輸入自動送出
		handleInvoicePhoneCarrierInput() {
			if (this.phoneCarrierInput.errorMessage === '' && this.phoneCarrierInput.number.length >= 8) {
				this.onSubmit()
			}
		},
	},
}
</script>

<style scoped lang="scss">
.checkout-modal {
	display: flex;
	flex-direction: row;
	height: calc(var(--vh, 1vh) * 36 + 30rem);
	min-height: calc(200px + 16rem);
	max-height: calc(var(--vh, 1vh) * 90);
	border-radius: 16px;
	padding: 20px;
	transition: 0.3s;
	box-shadow: none !important;

	.modal-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.5rem;
		font-weight: bold;
		color: #000;
		margin-bottom: 20px;
	}

	.modal-text {
		flex: 1;
		margin-top: 0 !important;
		padding: 0 !important;
		display: flex;
		flex-direction: column;
	}

	.list_item {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		font-size: 1.125rem;
		font-weight: bold;
		color: #000;
		margin: 6px 0;
	}

	.lastCount {
		padding: 12px;
		font-size: 1.25rem;
		margin-top: auto;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.line {
		height: 2px;
		width: 100%;
		background: #e1e1e1;
	}

	.modal-body {
		margin-top: 10px;
		border-radius: 8px;
		background: #f6f6f6;
		padding: 12px;
	}
}

.checkout-modal__box {
	flex: 1;
	display: flex;
	flex-direction: column;

	.selectDiscount {
	}

	& .right {
		background: #f6f6f6;
		border-radius: 8px;
		padding: 12px;
		display: flex;
		flex: 1;
		flex-direction: column;

		.select_btn {
			width: 100%;
		}

		.pay_type {
			height: 45px;
			display: flex;
			align-items: center;
			color: #000;
			font-size: 1.125rem;
			font-weight: bold;
		}

		.select_pay_type {
			height: 45px;
			width: calc(50% - 2px);
			transition: 0.3s;

			&.selectDiscount {
				border-color: rgba(214, 45, 45, 0.7);
				background: rgba(213, 40, 40, 0.1) !important;
				color: #d52828;
			}
		}

		/* 滑動容器 */
		.v-window-container {
			width: 100%;
			overflow: hidden;
		}

		/* 按鈕容器 */
		.button-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 20px;
		}

		.button-grid {
			display: grid;
			grid-gap: 10px;
			width: 100%;
			max-width: 400px;
		}

		.pay-button {
			height: 60px;
			width: 100%;
			transition: 0.3s;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			border-radius: 8px;
			box-sizing: border-box;
			overflow: hidden;
		}

		.pay-button span {
			display: inline-block;
			width: 100%;
			text-align: center;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			text-transform: none !important;
			font-size: 1.125rem;
		}

		.pay-button.selected {
			border-color: rgba(214, 45, 45, 0.7);
			background: rgba(213, 40, 40, 0.1) !important;
			color: #d52828;
		}

		.pagination-indicator {
			display: flex;
			gap: 8px;
			justify-content: center;
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.dot {
			width: 10px;
			height: 10px;
			background-color: #d7d7d7;
			border-radius: 50%;
			transition: background-color 0.3s, width 0.3s, height 0.3s; /* 增加平滑過渡效果 */
			cursor: pointer;
		}

		.dot.active {
			background-color: #294b71;
			width: 20px;
			height: 10px;
			border-radius: 8px;
		}

		.dot.one {
			background-color: #294b71;
			width: 50px;
			height: 10px;
			border-radius: 8px;
		}

		.discount_type {
			width: 100%;
			display: flex;
			border-radius: 24px;
			background: #fff;
			height: 48px;
			margin-bottom: 12px;
			padding: 6px;
			position: relative;

			.type_box {
				flex: 1;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1.125rem;
				font-weight: bold;
				color: #000;
				transition: 0.3s;
				z-index: 2;

				&.active {
					color: #fff;
				}
			}

			.type_box_select {
				border-radius: 20px;
				position: absolute;
				height: 36px;
				width: calc(50% - 6px);
				background: #294b71;
				transition: 0.3s;
				z-index: 1;

				&.right {
					transform: translateX(100%);
				}
			}
		}

		.keyboard {
			display: flex;
			flex: 1;
		}

		.keyboard_box {
			display: flex;
			align-content: flex-start;
			flex-wrap: wrap;
			justify-content: space-between;
			height: 100%;
			overflow: hidden;
			flex: 1;
		}

		.keyboard_item {
			width: calc(100% / 3 - 2px);
			height: calc(100% / 4 - 2px);
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 1px;
			cursor: pointer;
			font-size: calc(16px + 0.25rem);
			font-weight: bold;
			background: #fff;
			transition: 0.3s;

			&.active {
				border-color: rgba(214, 45, 45, 0.7);
				background: rgba(213, 40, 40, 0.1) !important;
				color: #d52828;
			}

			&:active {
				background: #dcdcdc;
			}
		}
	}

	.selectInvoiceType {
		border-color: rgba(214, 45, 45, 0.7);
		background: rgba(213, 40, 40, 0.1) !important;
		color: #d52828;
	}
}

@include rwd(589) {
	.checkout-modal {
		flex-direction: column;
		overflow-y: auto;
		flex: none;
		height: calc(var(--vh, 1vh) * 80 - 88px);

		.checkout-modal__box {
			padding: 0 !important;

			.actions {
				display: none;
			}

			.right {
				height: calc(var(--vh, 1vh) * 60 - 88px);
				flex: none;
			}
		}
	}
}

.actions {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	height: 68px;

	&::v-deep {
		&.v-card__actions {
			padding: 0;
		}

		.v-btn {
			height: 56px;
			flex: 1;
		}
	}

	&.mobile {
		margin-top: 0;
		display: none;
	}

	@include rwd(589) {
		height: 88px;
		padding: 16px 32px;
		.select_btn {
			margin-right: 8px;
		}

		&.mobile {
			display: flex;
		}
	}
}

.invoice-type-btn {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.web-scanner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.loading-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loading-animation {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* 載入動畫，這裡使用CSS Spinner來示範 */
.spinner {
	width: 50px;
	height: 50px;
	border: 5px solid #f3f3f3; /* 灰色邊框 */
	border-top: 5px solid #3498db; /* 蓝色顶部 */
	border-radius: 50%;
	animation: spin 2s linear infinite; /* 旋转动画 */
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loading-text {
}
</style>
